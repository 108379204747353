import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SectionTitle from '../../components/SectionTitle'
import ArticleRoll from '../../components/ArticleRoll'

const DailyBriefPage = ({
  data: {
    allMarkdownRemark,
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <section className="section">
      <Helmet title={`早报 | ${title}`} />
      <div className="container content">
        <div className="columns">
          <div
            className="column is-10 is-offset-1"
            style={{ marginBottom: '6rem' }}
          >
            <h1>
              <SectionTitle>早报</SectionTitle>
            </h1>
            <ArticleRoll allMarkdownRemark={allMarkdownRemark} columns="2" />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default DailyBriefPage

DailyBriefPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const DailyBriefPageQuery = graphql`
  query DailyBriefPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 100
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { 
        frontmatter: { 
          templateKey: { eq: "article" },
          isbrief: { eq: true }
      } }
    ) {
      edges {
        node {
          ...articleThumbFields
        }
      }
    }
  }
`
